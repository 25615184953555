<template>
  <div
    v-if="tableValue"
    :class="assignColor()"
    style="padding: 0 10px; height: 31px;"
  >
    {{ tableValue }}
  </div>
</template>
  
<script>
export default {
  props: {
    label: {
      required: true,
      default: 1,
      type: Number
    },
    tableValue: {
      required: true,
      default: '',
      type: String
    }
  },
  data(){
    return {
      colorArray: [
        {
          number: 1,
          value: 'blue'
        },
        {
          number: 2,
          value: 'yellow'
        },
        {
          number: 3,
          value: 'green'
        }
      ]
    }
  },
  methods: {
    assignColor(){
      const color = this.colorArray.find(item => 
        item.number === this.label)
      return color.value
    }
  }
}
</script>

<style scoped>
.blue {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color:  #437EEE;
  background: rgba(46, 36, 243, 0.16);
  border: 1px solid rgba(36, 67, 243, 0.16);
  border-radius: 8px;
}
.yellow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color:  #F38624;
  background: rgba(243, 134, 36, 0.16);
  border: 1px solid rgba(243, 134, 36, 0.16);
  border-radius: 8px;
}

.green {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #14B614;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.16);
  border-radius: 8px;
}
</style>
