<template>
  <table-content-hulling-activity
    :images="images"
    :list-hulling="listHulling"
    :list-header="listHeader"
  />
</template>

<script>
import TableContentHullingActivity from './component/table/index.vue'

export default {
  components: {
    TableContentHullingActivity
  },
  props: {
    images: {
      required: false,
      default: '',
      type: String
    },
    listHulling: {
      required: false,
      default: Object,
      type: Object
    }
  },
  data() {
    return {
      listHeader: [ 
        {
          key: 'vc_code',
          label: 'VCH Code',
          custom: 'sort'
        },
        {
          key: 'partner_name',
          label: 'Farmer Name',
          custom: 'sort'
        },
        {
          key: 'batch_id',
          label: 'ID Batch',
          custom: 'sort'
        },
        {
          key: 'species',
          label: 'Species',
          custom: 'sort'
        },
        {
          key: 'varieties',
          label: 'Varieties',
          custom: 'sort'
        },
        {
          key: 'process_type',
          label: 'Jenis Proses'
        },
        {
          key: 'weight_total',
          label: 'Weight'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'action',
          label: 'Action'
        }
      ]
    }
  }
}
</script>
