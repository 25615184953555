<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <header-hulling
        :images="images"
        :list-species="listSpecies"
        :list-process="listProcess"
        :list-stages="listHullingProcessStages"
        :filter-hulling="filterHulling"
        :reset-filter-hulling="resetFilterHulling"
        :meta-filter="metaFilter"
      />
      <content-hulling-activity 
        :images="images"
        :list-hulling="listHulling"
      />
      <pagination
        :total-data="totalData"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="currentPage"
        :current-limit="currentLimit"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import HeaderHulling from './HeaderHulling'
import ContentHullingActivity from './ContentHullingActivity'
import Pagination from '@/components/globals/Pagination'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderHulling,
    ContentHullingActivity,
    Pagination
  },
  props: {
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listSpecies: 'hullingActivity/list_species',
      listProcess: 'hullingActivity/list_process_type',
      listHullingProcessStages: 'hullingActivity/list_hulling_process_stages',
      listHulling: 'hullingActivity/list_hulling',
      metaFilter: 'hullingActivity/meta_filter'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listHulling(data) {
      this.totalData = data.meta.total
    }
  },
  async mounted() {
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingActivity/getListHulling',
      actionGetListFromMasterData: 'hullingActivity/getListFromMasterData'
    }),
    ...mapMutations({
      setMetaFilter: "hullingActivity/meta_filter"
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async loadPage() {
      await this.actionGetListFromMasterData({data_type: 'species'})
      await this.actionGetListFromMasterData({data_type: 'process_types'})
      await this.actionGetListFromMasterData({data_type: 'hulling_process_stages'})
      await this.actionGetListHulling({ page: this.currentPage, limit: this.currentLimit})
    },
    async filterHulling() {
      await this.actionGetListHulling({...this.metaFilter, page: 1, limit: 10})
    },
    async resetFilterHulling() {
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetListHulling({ page: this.currentPage, limit: this.currentLimit})
    },
    async changePage(page) {
      this.currentPage = parseInt(page)
      await this.actionGetListHulling({...this.metaFilter, page, limit: this.currentLimit})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      await this.actionGetListHulling({...this.metaFilter, limit: event.target.value, page: 1})
    }
  }
}
</script>
