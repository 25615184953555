<template>
  <div
    class="uk-form-stacked"
  >
    <div
      class="uk-child-width-1-1@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-3@l">
        <label class="uk-form-label">VCH Code / Farmer Name</label>
        <div class="uk-form-controls">
          <input
            v-model="search"
            name="search"
            class="uk-input"
            type="text"
            placeholder="Search VCH Code or Farmer Name"
            autocomplete="off"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls">
          <label class="uk-form-label">Species</label>
          <multiselect 
            v-model="species" 
            label="name" 
            name="species" 
            track-by="id" 
            placeholder="All" 
            open-direction="bottom" 
            :options="listSpecies"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :show-labels="false"
          >
            <span slot="noResult">Oops! Data tidak ditemukan.</span>
          </multiselect>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls uk-position-relative withlabel">
          <label class="uk-form-label">Jenis Proses</label>
          <multiselect 
            v-model="process_type" 
            label="name" 
            name="process_type" 
            track-by="id" 
            placeholder="All" 
            open-direction="bottom" 
            :options="listProcess"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :show-labels="false"
          >
            <span slot="noResult">Oops! Data tidak ditemukan.</span>
          </multiselect>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-4@l uk-flex uk-flex-bottom">
        <button
          class="uk-button uk-button-primary uk-margin-small-right uk-padding-small"
          @click.prevent="handleSearch"
        >
          <img
            :src="`${images}/icon/ic_search_white.svg`"
            class="uk-margin-small-right"
            alt=""
            loading="lazy"
          >
          Search
        </button>
        <button
          class="uk-button uk-button-default uk-padding-remove"
          @click.prevent="handleReset"
        >
          Reset Filter
        </button>
      </div>
      <div class="uk-width-1-1">
        <ul uk-tab>
          <li class="uk-actives">
            <a @click="filterTabHandler('')">Semua Aktivitas</a>
          </li>
          <li
            v-for="(item, i) in listStages"
            :key="i"
          >
            <a @click="filterTabHandler(item.id)">{{ item.name == 'Prehull' ? 'Pre-Hull Drying' : item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { STATUS_FILTER } from '@/utils/constant'
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    listSpecies: {
      required: false,
      default: ()=>[],
      type: Array
    },
    listProcess: {
      required: false,
      default: ()=>[],
      type: Array
    },
    listStages: {
      required: false,
      default: ()=>[],
      type: Array
    },
    filterHulling: {
      required: true,
      type: Function
    },
    resetFilterHulling: {
      required: true,
      type: Function
    },
    metaFilter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      search: '',
      species: '',
      process_type: '',
      statusFilter: STATUS_FILTER
    }
  },
  methods: {
    ...mapMutations({
      setMetaFilter: "hullingActivity/SET_META_FILTER"
    }),
    handleSearch() {
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search || null,
        species_id: this.species?.id,
        process_type_id: this.process_type?.id
      })
      this.filterHulling()
    },
    filterTabHandler(tabId){
      this.setMetaFilter({
        ...this.metaFilter,
        process_stage_id: tabId
      })
      this.filterHulling()
    },
    handleReset() {
      this.search = null
      this.species = null,
      this.status = null
      this.process_type = null

      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search,
        species: this.species ,
        status: this.status,
        process_type: this.process_type
      })
      this.resetFilterHulling()
    }
  }
}
</script>

<style scoped>
.uk-tab > * > a {
  font-size: 14px;
  font-weight: 700;
}
</style>
